<template>
	<div class="fund-detail-page">
		
		<van-tabs v-model="tab_active" title-active-color="#EE0A24" class="tab-main">
				
			<van-tab title="介绍">
				<div class="tab-content">
			
					<section class="layer fund-desc">
						<h4 class="layer-title">基金介绍</h4>
						<div class="layer-content">
							<div class="desc" v-html="fund.description"></div>
						</div>
					</section>
			
					<section class="layer fund-notice">
						<h4 class="layer-title">风险提示</h4>
						<div class="layer-content">
							<div class="desc" v-html="fund.notice"></div>
						</div>
					</section>
			
				</div>
			</van-tab>
			
			<van-tab title="报价">
				<div class="tab-content">

					<section class="layer fund-detail">
						<div class="layer-content">
							<p class="name">{{fund.name}}</p>
							<p class="code">{{fund.code}}</p>
							<van-row class="item-value">
								<van-col span="9" class="ratio">{{fund.ratio}}</van-col>
								<van-col span="6" class="net text-center">{{fund.net}}</van-col>
								<van-col span="9" class="surplus-total text-right">{{fund.surplus_total}}</van-col>
							</van-row>
							<van-row class="item-name">
								<van-col span="9">成立以来涨幅</van-col>
								<van-col span="6" class="text-center">单位净值(港元)</van-col>
								<van-col span="9" class="text-right">剩余额度</van-col>
							</van-row>
						</div>
					</section>

					<section class="layer fund-advantage" v-if="fund_advantage.length > 0">
						<h4 class="layer-title">产品亮点</h4>
						<div class="layer-content">
							<van-row v-for="(item, key) in fund_advantage" :key="key" class="item">
								<van-col span="24" class="title"><span class="index">{{key + 1}}</span>{{item.title}}</van-col>
								<van-col span="24" class="value">{{item.value}}</van-col>
							</van-row>
						</div>
					</section>
					
					<section class="layer" v-if="achievements_v.length > 0">
						<h4 class="layer-title">业绩走势</h4>
						<div class="layer-content">
							<div id="myChart" ref="chart" style="width:100%;height:260px;"></div>
						</div>
					</section>

					<section class="layer" v-if="history_nets.length > 0">
						<h4 class="layer-title">历史净值 <span class="more" @click="nets">更多</span></h4>
						<div class="layer-content">
							<van-row class="table-title">
								<van-col span="10">日期</van-col>
								<van-col span="4" class="text-center">单位净值</van-col>
								<van-col span="10" class="text-right">日涨跌幅</van-col>
							</van-row>
							<van-row v-for="(item, key) in history_nets" :key="key" class="table-item">
								<van-col span="10" class="date">{{item.date}}</van-col>
								<van-col span="4" class="value text-center">{{item.value}}</van-col>
								<van-col span="10" class="ratio text-right color-negative" v-if="item.ratio < 0">{{item.ratio}}%</van-col>
								<van-col span="10" class="ratio text-right color-zero" v-if="item.ratio == 0">{{item.ratio}}%</van-col>
								<van-col span="10" class="ratio text-right color-just" v-if="item.ratio > 0">{{item.ratio}}%</van-col>
							</van-row>
						</div>
					</section>
					
					<section class="layer" v-if="history_profit.length > 0">
						<h4 class="layer-title">历史收益</h4>
						<div class="layer-content">
							<van-row class="table-title">
								<van-col span="18">周期</van-col>
								<van-col span="6" class="text-right">收益</van-col>
							</van-row>
							<van-row v-for="(item, key) in history_profit" :key="key" class="table-item">
								<van-col span="18">{{item.name}}</van-col>
								<van-col span="6" class="text-right">{{item.value}}%</van-col>
							</van-row>
						</div>
					</section>

				</div>
			</van-tab>

			<van-tab title="简况">
				<div class="tab-content">

					<section class="layer fund-detail">
						<div class="layer-content">
							<p class="name">{{fund.name}}</p>
							<p class="code">{{fund.code}}</p>
						</div>
					</section>

					<section class="layer">
						<h4 class="layer-title">基金概况</h4>
						<div class="layer-content">
							<van-row v-for="(item, key) in fund_info" :key="key" class="table-item">
								<van-col span="6" class="name">{{item.name}}</van-col>
								<van-col span="18">{{item.value}}</van-col>
							</van-row>
						</div>
					</section>

					<section class="layer">
						<h4 class="layer-title">基金经理</h4>
						<div class="layer-content">
							<van-row v-for="(item, key) in fund_manager" :key="key" class="table-item">
								<van-col span="6" class="name">{{item.name}}</van-col>
								<van-col span="18">{{item.duty}}</van-col>
							</van-row>
						</div>
					</section>


				</div>
			</van-tab>
		</van-tabs>

	</div>
</template>

<script>
	import echarts from 'echarts'

	export default {
		name: 'fund_detail',
		data() {
			return {

				id: 0,

				tab_active: 0,
				fund: {
					name: "",
					code: "",
					ratio: "",
					net: "",
					surplus_total: "",
				},

				fund_info: [],
				fund_manager: [],
				fund_advantage: [],
				history_profit: [],
				history_nets: [],

				achievements_x: [],
				achievements_v: [],
			}
		},
		mounted() {
			this.id = this.$route.query.id
		
			this.loadData()
		},
		activated() {
			//	如果第二次进入修改的数据不同，则重新获取数据
			if (this.$route.query.id != this.id) {
				this.id = this.$route.query.id
		
				this.loadData()
			}
		},
		updated() {
			this.drawLine()
		},
		methods: {
			nets(){
				this.$router.push('/fund/nets?id=' + this.id) 
			},
			drawLine() {
				var bar_dv = this.$refs.chart

				if(bar_dv){
					let myChart = echarts.init(bar_dv)
					
					let option = {
						grid: // 设置网格样式
						{
							left: '25px',
							top: '10px',
							right: '15px',
							bottom: '20px'
						},
						xAxis: {
							type: 'category',
							boundaryGap: false,
							data: this.achievements_x,
							axisLine: {
								show: false
							},
							axisLabel: {
								color: '#999999',
								fontSize: 10
							},
							axisTick: {
								show: false
							}
						},
						yAxis: {
							type: 'value',
							axisLine: {
								show: false
							},
							axisLabel: {
								color: '#999999',
								fontSize: 10
							},
							splitLine: {
								show: true,
								lineStyle: {
								color: "#eeeeee",
									opacity: 0.5
								}
							},
							axisTick: {
								show: false
							}
						},
						series: [{
							data: this.achievements_v,
							type: 'line',
							lineStyle: {
								width: 1
							},
							areaStyle: {
								color: {
									type: 'linear',
									x: 0,
									y: 0,
									x2: 0,
									y2: 1,
									colorStops: [
										{
											offset: 0, color: '#73bee5' // 0% 处的颜色
										}, 
										{
											offset: 1, color: 'white' // 100% 处的颜色
										},
									],
									global: false // 缺省为 false
								}
							},
							symbol: "none"
						}]
					}

					myChart.setOption(option);

					myChart.on()
				}
			},

			loadData() {
				this.$axios({
					method: 'post',
					url: 'fund_fund/detail', 
					data: {
						id: this.id
					}
				}).then ((res) => {
					if (res.success) {
						
						this.fund_advantage = JSON.parse(res.data.fund_advantage)
						this.fund_info = JSON.parse(res.data.fund_info)
						this.fund_manager = JSON.parse(res.data.fund_manager)
						this.history_profit = JSON.parse(res.data.history_profit)
						this.history_nets = JSON.parse(res.data.history_nets)

						let achievements = JSON.parse(res.data.history_achievements)
						if(achievements.length > 0){
							for (var j in achievements) {
								this.achievements_x.push(achievements[j].date)
								this.achievements_v.push(achievements[j].value)
								
							}
						}

						this.fund.name = res.data.fund.name
						this.fund.code = res.data.fund.code
						this.fund.ratio = res.data.fund.ratio
						this.fund.net = res.data.fund.net	
						this.fund.surplus_total = res.data.fund.surplus_total	
						this.fund.description = res.data.fund.description	
						this.fund.notice = res.data.fund.notice	

						this.drawLine()
					}
				})
			}
		},
	}
</script>